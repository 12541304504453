class PerformanceMetric{
    oneDay?: number;

    oneMonth?: number;

    threeMonths?: number;

    weekToDate?: number;

    monthToDate?: number;

    yearToDate?: number;

    oneYear?: number;

    twoYears?: number;

    threeYears?: number;

    threeYearsAnnualised?: number;

    fiveYears?: number;

    fiveYearsAnnualised?: number;

    inception?: number;

    inceptionAnnualised?: number;

    volatilityInceptionAnnualised?: number;

    error?: string;

    effectiveAt: string;

    fromEffectiveAt: string;

    toEffectiveAt: string;

    portfolioScope: string;

    portfolioCode: string;

    benchmarkCode: string;

    benchmarkInception?: number;

    benchmarkOneDay?: number;

    benchmarkOneMonth?: number;

    benchmarkThreeMonths?: number;

    benchmarkWeekToDate?: number;

    benchmarkMonthToDate?: number;

    benchmarkYearToDate?: number;

    benchmarkInceptionAnnualised?: number;

    benchmarkVolatilityInceptionAnnualised?: number;

    benchmarkOneYear?: number;

    benchmarkTwoYears?: number;

    benchmarkThreeYears?: number;

    benchmarkThreeYearsAnnualised?: number;

    benchmarkFiveYears?: number;

    benchmarkFiveYearsAnnualised?: number;

    benchmarkError?: string

    oneYearAnnualised?: number;

    twoYearsAnnualised?: number;

    benchmarkOneYearAnnualised?: number;

    benchmarkTwoYearsAnnualised?: number;

    constructor(portfolioScope: string, 
        portfolioCode: string,
        effectiveAt: string,
        fromEffectiveAt: string,
        toEffectiveAt: string,
        benchmarkCode: string,
        oneDay?: number,
        oneMonth?: number,
        threeMonths?: number,
        weekToDate?: number,
        monthToDate?: number,
        yearToDate?: number,
        oneYear?: number,
        twoYears?: number,
        threeYears?: number,
        threeYearsAnnualised?: number,
        fiveYears?: number,
        fiveYearsAnnualised?: number,
        inception?: number,
        inceptionAnnualised?: number,
        volatilityInceptionAnnualised?: number,
        error?: string,
        benchmarkOneDay?: number,
        benchmarkOneMonth?: number,
        benchmarkThreeMonths?: number,
        benchmarkWeekToDate?: number,
        benchmarkMonthToDate?: number,
        benchmarkYearToDate?: number,
        benchmarkOneYear?: number,
        benchmarkTwoYears?: number,
        benchmarkThreeYears?: number,
        benchmarkThreeYearsAnnualised?: number,
        benchmarkFiveYears?: number,
        benchmarkFiveYearsAnnualised?: number,
        benchmarkInception?: number,
        benchmarkInceptionAnnualised?: number,
        benchmarkVolatilityInceptionAnnualised?: number,
        benchmarkError?: string,
        oneYearAnnualised?: number,
        twoYearsAnnualised?: number,
        benchmarkOneYearAnnualised?: number,
        benchmarkTwoYearsAnnualised?: number
        ){
            this.portfolioScope = portfolioScope;
            this.portfolioCode = portfolioCode;
            this.effectiveAt = effectiveAt;
            this.fromEffectiveAt = fromEffectiveAt;
            this.toEffectiveAt = toEffectiveAt;
            this.benchmarkCode = benchmarkCode;
            this.oneDay = oneDay;
            this.oneMonth = oneMonth;
            this.threeMonths = threeMonths;
            this.weekToDate = weekToDate;
            this.monthToDate = monthToDate;
            this.yearToDate = yearToDate;
            this.oneYear = oneYear;
            this.twoYears = twoYears;
            this.threeYears = threeYears;
            this.threeYearsAnnualised = threeYearsAnnualised
            this.fiveYears = fiveYears;
            this.fiveYearsAnnualised = fiveYearsAnnualised
            this.inception = inception;
            this.inceptionAnnualised = inceptionAnnualised;
            this.volatilityInceptionAnnualised = volatilityInceptionAnnualised;
            this.error = error;
            this.benchmarkOneDay = benchmarkOneDay;
            this.benchmarkOneMonth = benchmarkOneMonth;
            this.benchmarkThreeMonths = benchmarkThreeMonths;
            this.benchmarkWeekToDate = benchmarkWeekToDate;
            this.benchmarkMonthToDate = benchmarkMonthToDate;
            this.benchmarkYearToDate = benchmarkYearToDate;
            this.benchmarkOneYear = benchmarkOneYear;
            this.benchmarkTwoYears = benchmarkTwoYears;
            this.benchmarkThreeYears = benchmarkThreeYears;
            this.benchmarkThreeYearsAnnualised = benchmarkThreeYearsAnnualised
            this.benchmarkFiveYears = benchmarkFiveYears;
            this.benchmarkFiveYearsAnnualised = benchmarkFiveYearsAnnualised
            this.benchmarkInception = benchmarkInception;
            this.benchmarkInceptionAnnualised = benchmarkInceptionAnnualised;
            this.benchmarkVolatilityInceptionAnnualised = benchmarkVolatilityInceptionAnnualised;
            this.benchmarkError = benchmarkError;
            this.oneYearAnnualised = oneYearAnnualised;
            this.twoYearsAnnualised = twoYearsAnnualised;
            this.threeYearsAnnualised = threeYearsAnnualised;
            this.benchmarkOneYearAnnualised = benchmarkOneYearAnnualised;
            this.benchmarkTwoYearsAnnualised = benchmarkTwoYearsAnnualised;
        }
}



export default PerformanceMetric;