class ClientPosition{

    id: number;

    rawId: number;

    custodianId: number;

    fileProcessId: number;

    assetClass: string;

    assetSubClass?: string;

    accountNumber: string;

    positionDate: string;

    currency: string;

    exchangeRate: number;

    quantity: number;

    avgorUnitCost: number;

    marketPrice: number;

    marketValue: number;

    securityName: string;

    securityIsin: string;

    securityCusip?: string;

    securityRic: string;

    securitySymbol?: string;

    exchangeCode?: string;

    marketValueReportingCurrency: number;

    accruedInterest: number;

    constructor(
        id: number,
        rawId: number,
        custodianId: number,
        fileProcessId: number,
        assetClass: string,
        accountNumber: string,
        positionDate: string,
        currency: string,
        exchangeRate: number,
        quantity: number,
        avgorUnitCost: number,
        marketPrice: number,
        marketValue: number,
        securityName: string,
        securityIsin: string,
        securityRic: string,
        marketValueReportingCurrency: number,
        accruedInterest: number,
        assetSubClass?: string,
        securityCusip?: string,
        securitySymbol?: string,
        exchangeCode?: string
        ){
            this.id = id;
            this.rawId = rawId;
            this.custodianId = custodianId;
            this.fileProcessId = fileProcessId;
            this.assetClass = assetClass;
            this.accountNumber = accountNumber;
            this.positionDate = positionDate;
            this.currency = currency;
            this.exchangeRate = exchangeRate;
            this.quantity = quantity;
            this.avgorUnitCost = avgorUnitCost;
            this.marketPrice = typeof marketPrice === 'string' ? Number(marketPrice) : marketPrice;
            this.marketValue = marketValue;
            this.securityName = securityName;
            this.securityIsin = securityIsin;
            this.securityRic = securityRic;
            this.marketValueReportingCurrency = marketValueReportingCurrency;
            this.accruedInterest = accruedInterest;
            this.assetSubClass = assetSubClass;
            this.securityCusip = securityCusip;
            this.securitySymbol = securitySymbol;
            this.exchangeCode = exchangeCode;
    }

    identifiers(): {[identifierType: string]: string} {

        let identifierValues: {[identifierType: string]: string}  = {};

        if (this.securitySymbol !== undefined){
            identifierValues["Ticker"] = this.securitySymbol;
        }

        if (this.securityIsin !== undefined){
            identifierValues["ISIN"] = this.securityIsin;
        }

        if (this.securityCusip !== undefined){
            identifierValues["Cusip"] = this.securityCusip;
        }

        if (this.securityRic !== undefined){
            identifierValues["RIC"] = this.securityRic;
        }

        return identifierValues;
    }

    calculateWeight(holdings: ClientPosition[]): number{
        var totalMarketValue = holdings.reduce(((previousValue, currentValue) => previousValue + currentValue.marketValueReportingCurrency), 0);
        return this.marketValueReportingCurrency / totalMarketValue;
    };
}

export default ClientPosition;