import PerformanceMetric from "./performanceMetric";

class PerformanceMetrics{
    
    metrics: {[effectiveDate: string]: PerformanceMetric};

    constructor(metrics: PerformanceMetric[], 
        ){
            this.metrics = {};

            metrics.forEach((metric) => {
                this.metrics[metric.effectiveAt] = metric;
            });
        }

    isWeekend = (date: string): boolean => {
        return (new Date(Date.parse(date)).getDay() % 6) == 0
    }
    
    getkeyInvestorDocumentVolatiltiy = (): number => {
        // As per https://www.esma.europa.eu/sites/default/files/library/2015/11/10_673.pdf

        let dailyReturns = Object
            .values(this.metrics)
            .filter((metric) => (!this.isWeekend(metric.effectiveAt) && (metric.oneDay != null)  && (metric.oneDay != undefined)))
            .map((metric) => metric.oneDay!);

        let count = Object.keys(dailyReturns).length;
        let sum = Object.values(dailyReturns).reduce((prev, current) => {
            prev += current
            return prev
        }, 0);
        let mean = sum / count;

        let stdDev = Object.values(dailyReturns).reduce((prev, current) => {
            prev += Math.pow(((current || 0) - mean), 2)
            return prev
        }, 0)

        let annualisedVolatility = Math.pow(((256 / (count - 1)) * stdDev), 0.5);

        return annualisedVolatility;
    }

    getMaxDrawDownSinceInception = (): number => {
        let index = 100;
        let maxIndex = 100;
        let dates = Object.keys(this.metrics);
        let datesSorted = dates.sort((a: string, b:string) => a.localeCompare(b));
        return Math.min(...datesSorted.map(date => {
            let metric = this.metrics[date]
            let currentIndex = index * (1 + (metric.inception || 0))
            if (currentIndex > maxIndex){
                maxIndex = currentIndex;
            }
            let drawdown = (currentIndex - maxIndex) / maxIndex;
            return drawdown;
        }))

    }

    getInceptionDate = (): Date => {
        let minDate = Object.values(this.metrics)[0].fromEffectiveAt;
        return new Date(Date.parse(minDate))
    }

    getLastDayInMonth = (month: number, year: number): PerformanceMetric => {
        let dates = Object.keys(this.metrics);
        let datesFiltered = dates.filter((date) => {
            let dateParsed = new Date(Date.parse(date));
            return (dateParsed.getMonth() == month) && (dateParsed.getFullYear() == year)
        }).sort((a: string, b:string) => a.localeCompare(b));
        return this.metrics[datesFiltered.slice(-1)[0]];
    }

    getLastDayInYear = (year: number): PerformanceMetric => {
        let dates = Object.keys(this.metrics);
        let datesFiltered = dates.filter((date) => {
            let dateParsed = new Date(Date.parse(date));
            return dateParsed.getFullYear() == year
        }).sort((a: string, b:string) => a.localeCompare(b));
        return this.metrics[datesFiltered.slice(-1)[0]];
    }

    getMostRecent = (): PerformanceMetric => {
        let dates = Object.keys(this.metrics);
        let datesFiltered = dates.sort((a: string, b:string) => a.localeCompare(b));
        return this.metrics[datesFiltered.slice(-1)[0]];
    }

    getFirst = (): PerformanceMetric => {
        let dates = Object.keys(this.metrics);
        let datesFiltered = dates.sort((a: string, b:string) => a.localeCompare(b));
        return this.metrics[datesFiltered[0]];
    }
    
    static getSemiAnnual = (performanceMetrics: PerformanceMetric[]): PerformanceMetric[] => {

        let semiAnnual:PerformanceMetric[] = [];

        let sorted = performanceMetrics.sort((a: PerformanceMetric, b:PerformanceMetric) => {
            return a.effectiveAt.localeCompare(b.effectiveAt)
        });

        let currentDay = 0;
        let previousMetric: PerformanceMetric = sorted[0]
        
        sorted.slice(1).forEach(metric => {
            
            let date = new Date(Date.parse(metric.effectiveAt));
            let previousDate = new Date(Date.parse(previousMetric.effectiveAt));

            if (((previousDate.getMonth() + 1) % 6 == 0) && (date.getDate() < currentDay)){
                semiAnnual.push(previousMetric)
            }

            currentDay = date.getDate();
            previousMetric = metric;
        })

        return semiAnnual;
    }

    static getBiMonthly = (performanceMetrics: PerformanceMetric[]): PerformanceMetric[] => {

        let biMonthly:PerformanceMetric[] = [];

        let sorted = performanceMetrics.sort((a: PerformanceMetric, b:PerformanceMetric) => {
            return a.effectiveAt.localeCompare(b.effectiveAt)
        });

        let currentDay = 0;
        let previousMetric: PerformanceMetric = sorted[0]
        
        sorted.slice(1).forEach(metric => {
            
            let date = new Date(Date.parse(metric.effectiveAt));
            let previousDate = new Date(Date.parse(previousMetric.effectiveAt));

            if (((previousDate.getMonth() + 1) % 2 == 0) && (date.getDate() < currentDay)){
                biMonthly.push(previousMetric)
            }

            currentDay = date.getDate();
            previousMetric = metric;
        })

        return biMonthly;
    }

    best12Months = (): number => {
        const values = Object
            .values(this.metrics)
            .filter((metric) => !this.isWeekend(metric.effectiveAt)) // Exclude weekends
            .map((metric) => metric.oneYear)
            .filter((oneYear): oneYear is number => oneYear != null); // Filter out null or undefined values

        return values.length > 0 ? Math.max(...values) : 0;
    }

    worst12Months = (): number => {
        const values = Object
            .values(this.metrics)
            .filter((metric) => !this.isWeekend(metric.effectiveAt)) // Exclude weekends
            .map((metric) => metric.oneYear)
            .filter((oneYear): oneYear is number => oneYear != null); // Filter out null or undefined values

        return values.length > 0 ? Math.min(...values) : 0;
    }

    monthCount = (positive: boolean = true): number => {

        let dates = Object.keys(this.metrics);
        let datesFiltered = dates.sort((a: string, b:string) => a.localeCompare(b));
        let minDate = new Date(Date.parse(datesFiltered[0]));
        let maxDate = new Date(Date.parse(datesFiltered.slice(-1)[0]));
        let currentMonth = minDate.getMonth();
        let currentYear = minDate.getFullYear();
        let yearMonths: [number, number][] = [];    

        while ((currentMonth !== maxDate.getMonth()) || (currentYear !== maxDate.getFullYear())){
            yearMonths.push([currentYear, currentMonth])
            if (currentMonth == 11){
                currentYear += 1
                currentMonth = 0
            } else {
                currentMonth += 1
            }
        }
        yearMonths.push([currentYear, currentMonth])

        let monthCount = 0;

        yearMonths.forEach((currentYearMonth: [number, number]) => {

            let mtdPerformance = this.getLastDayInMonth(currentYearMonth[1], currentYearMonth[0]).monthToDate || 0;
            if ((positive) && (mtdPerformance > 0)) {
                monthCount += 1
            } else if (!(positive) && (mtdPerformance < 0)) {
                monthCount += 1
            }
        }, 0)

        return monthCount;
    }
}



export default PerformanceMetrics;