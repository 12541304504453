import ClientPosition from "../clientPosition";
import ClientPositionStore from "./clientPositionStore";
import CSVReader from "react-csv-reader";
import React, { ReactElement } from "react";
import ClientPositionWithLookThrough from "../clientPositionWithLookThrough";
import ClientPositionExtended from "../clientPositionExtended";

class CsvFileClientPositionStore implements ClientPositionStore{

    private data: ClientPosition[];
    csvReader: ReactElement;

    constructor(label: string){
        this.data = [];
        this.csvReader = <CSVReader
            label={label}
            onFileLoaded={(data, fileInfo, originalFile) => this.LoadCsvClientHoldings(data, fileInfo, originalFile)}
        />;
    }

    setData(data: ClientPosition[]){
        this.data = data;
    }

    private LoadCsvClientHoldings = (data: Array<Array<string>>, fileInfo: any, originalFile: any) => {
        var clientPositions = data.slice(1 ).map(row => {
            return new ClientPosition(
                parseInt(row[0]),
                parseInt(row[1]),
                parseInt(row[2]),
                parseInt(row[3]),
                row[4],
                row[6],
                row[7],
                row[8],
                parseFloat(row[9]),
                parseFloat(row[10]),
                parseFloat(row[11]),
                parseFloat(row[12]),
                parseFloat(row[13]),
                row[14],
                row[15],
                row[17],
                parseFloat(row[20]),
                parseFloat(row[21]),
                row[5],
                row[16],
                row[18],
                row[19]
            )
        });
        this.setData(clientPositions)
    };

    getClientPositions(
        representativeDate: string,
        accountNumbers: string[]
    ): Promise<ClientPosition[]>{

        return Promise.resolve(this.data);
    }

    getClientPositionsWithLookThrough(
        representativeDate: string,
        accountNumbers: string[],
        lookthroughDepth: string,
        aggregateMethod: string
    ): Promise<ClientPositionWithLookThrough[]>{

        throw Error("Not Implemented");
    }

    getClientPositionsExtended(
        representativeDate: string,
        accountNumber: string,
        portfolioScope: string,
        recipe: string,
        reportingCurrency: string,
        modelId: string,
        keysToGroupBy: string,
        useCached: boolean
    ): Promise<ClientPositionExtended[]>{

        throw Error("Not Implemented");
    }
}

export default CsvFileClientPositionStore;
