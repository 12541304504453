import ClientPositionStore from "../models/clientPositions/stores/clientPositionStore";
import ClientPosition from "../models/clientPositions/clientPosition";
import ClientPositionWithLookThrough from "../models/clientPositions/clientPositionWithLookThrough";
import ClientPositionExtended from "../models/clientPositions/clientPositionExtended";

class ClientPositionsViewModel {

    store: ClientPositionStore;

    constructor(clientPositionsStore: ClientPositionStore) {
        this.store = clientPositionsStore
    }

    getClientPositions(
        representativeDate: string,
        accountNumbers: string[]
    ): Promise<ClientPosition[]>{
        return this.store.getClientPositions(representativeDate, accountNumbers);
    }

    getClientPositionsWithLookThrough(
        representativeDate: string,
        accountNumbers: string[],
        lookThroughDepth?: string,
        aggregateMethod?: string
    ): Promise<ClientPositionWithLookThrough[]>{
        return this.store.getClientPositionsWithLookThrough(representativeDate, accountNumbers, lookThroughDepth, aggregateMethod);
    }

    getClientPositionsExtended(
        clientAccountNumber: string,
        reportDate: string,
        portfolioScope: string,
        recipe: string,
        reportingCurrency: string,
        modelId: string,
        keysToGroupBy: string,
        useCached: boolean
    ): Promise<ClientPositionExtended[]> {
        const positions = this.store.getClientPositionsExtended(clientAccountNumber, reportDate, portfolioScope, recipe, reportingCurrency, modelId, keysToGroupBy, useCached);
        return positions;
    }
}

export default ClientPositionsViewModel