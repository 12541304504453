import React from 'react';
import ClientReportQuarterlyTableInfo from "../../../interfaces/props/reports/clientReportQuarterlyTableInfo";
import Column from "../../../interfaces/column";
import TableRow from "./tableRow";
import TableHeader from "../tableHeader";
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import TableTitle from "../tableTitle";

function ClientReportQuarterlyTable(props: ClientReportQuarterlyTableInfo) {

    const {
        tableData,
        sumColumns = [],
        filter = [],
        suppressColumnNames = false
    } = props;

    const rowColourOdd = '#f4f0ec';
    const rowColourEven = '#ffffff';
    const headerColour = '#ffffff';
    const headerBackgroundColour = '#1E3922';
    const titleColour = '#ffffff';
    const titleBackgroundColour = '#ffffff';
    const title = '';

    // Create styles
    const styles = StyleSheet.create({
        table: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            flexShrink: 0
        },
        rowOdd: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            backgroundColor: rowColourOdd
        },
        rowEven: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            backgroundColor: rowColourEven
        },
        rowTotal: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            backgroundColor: rowColourEven
        },
        cell: {
            paddingLeft: 10,
            paddingRight: 10,
            color: '#3C3C3B',
            fontSize: 6,
            paddingTop: 2,
            paddingBottom: 2,
            fontFamily: "Arial"
        },
        headerRow: {
            backgroundColor: headerBackgroundColour,
        },
        titleRow: {
            backgroundColor: titleBackgroundColour,
        },
        headerCell: {
            color: headerColour,
            textAlign: 'center'
        },
        titleCell: {
            color: titleColour,
            textAlign: 'center'
        },
        heading: {
          color: "#ffffff",
          fontFamily: "Merriweather",
          fontWeight: "normal",
          fontSize: 28,
          paddingBottom: 2
        },
        subHeading: {
          color: "#1E3922",
          fontFamily: "Merriweather",
          fontWeight: "semibold",
          fontSize: 24,
          paddingBottom: 10
        },
        title: {
          fontWeight: "semibold",
          paddingTop: 4,
          paddingBottom: 4,
          fontSize: 8
        },
        standardText: {
          color: "#3C3C3B",
          fontFamily: "Merriweather",
          fontWeight: "light",
          fontSize: 6,
          // lineHeight: 1.2,
          paddingBottom: 2
        }
    });

    const columns: Column[] = tableData.columns;
    const rows: { [source: string]: { [attribute: string]: string } }[] = tableData.rows;

    // Determine title style based on recursion level (filter length)
    const getTitleStyle = (depth: number) => {
        if (depth === 2) {
            return [styles.standardText, styles.title]
        } else if (depth === 1) {
            return [styles.standardText]
        } else {
            return [styles.standardText]
        }
    };


    // Function to calculate totals for filtered rows
    const calculateTotals = (rows: any[], sumColumns: string[]): { [field: string]: number | null } => {
        const columnTotals: { [field: string]: number } = {};

        sumColumns.forEach((field) => {
            columnTotals[field] = 0;
        });

        rows.forEach((row) => {
            sumColumns.forEach((field) => {
                const value = row[field];
                if (typeof value === 'string' && value.endsWith('%')) {
                    // Remove % and convert to number
                    const numericValue = parseFloat(value.slice(0, -1));
                    if (!isNaN(numericValue)) {
                        columnTotals[field] += numericValue;
                    }
                } else if (!isNaN(value)) {
                    columnTotals[field] += Number(value);
                }
            });
        });

        // Convert totals back to percentage strings for percentage columns
        Object.keys(columnTotals).forEach((field) => {
            const sampleValue = rows[0]?.[field];
            if (typeof sampleValue === 'string' && sampleValue.endsWith('%')) {
                columnTotals[field] = columnTotals[field]; // Keep as number for now
            }
        });

        return columnTotals;
    };

    // Function to render table recursively based on the filter
    const renderTableRecursive = (tableName: string, rows: any[], currentFilter: string[], isTopLevel: boolean): JSX.Element[] | null => {
        // Start case: Empty table, return empty row.
        if (!rows || rows.length === 0) {
            return null;
        };
        if (currentFilter.length === 0) {
            // Base case: Render rows directly without further filtering
            const columnTotals = calculateTotals(rows, sumColumns);
            const tableRows = rows.map((row, index) => {
                let rowStyles = styles.rowOdd;
                if ((index + 1) % 2 === 0) {
                    rowStyles = styles.rowEven;
                }

                return <TableRow key={index} columns={columns} row={row} rowStyles={rowStyles} cellStyles={styles.cell} />;
            });

            // Return table content with totals here (for base case)
            return [
                <View style={styles.table} key="table">
                    {tableRows}
                    {sumColumns.length > 0 && (
                        <TableRow
                            key="total"
                            columns={columns}
                            row={columnTotals}
                            rowStyles={styles.rowTotal}
                            cellStyles={styles.cell}
                            isTotal={true}
                        />
                    )}
                </View>
            ];
        } else {
            // Recursive case: Filter rows and render nested tables
            const currentFilterField = currentFilter[0];
            const remainingFilters = currentFilter.slice(1);
            const uniqueValues = new Set(rows.map(row => row[currentFilterField]));

            // Check if only one unique value exists for the current filter level
            if (uniqueValues.size === 1) {
                // Skip recursion and render rows directly
                const singleValue = Array.from(uniqueValues)[0];
                const filteredRows = rows.filter(row => row[currentFilterField] === singleValue);

                const columnTotals = calculateTotals(filteredRows, sumColumns);
                const tableRows = filteredRows.map((row, index) => {
                    let rowStyles = styles.rowOdd;
                    if ((index + 1) % 2 === 0) {
                        rowStyles = styles.rowEven;
                    }

                    return <TableRow key={index} columns={columns} row={row} rowStyles={rowStyles} cellStyles={styles.cell} />;
                });

                return [
                    <View key={singleValue}>
                        {tableRows}
                        {sumColumns.length > 0 && (
                            <TableRow
                                key="total"
                                columns={columns}
                                row={columnTotals}
                                rowStyles={styles.rowTotal}
                                cellStyles={styles.cell}
                                isTotal={true}
                            />
                        )}
                    </View>
                ];
            }

            const tableSections = Array.from(uniqueValues).map((value) => {
                const filteredRows = rows.filter(row => row[currentFilterField] === value);

                return (
                    <View key={value}>
                        <Text style={getTitleStyle(currentFilter.length)}>{value}</Text>
                        {renderTableRecursive(value, filteredRows, remainingFilters, false)}
                    </View>
                );
            }).filter(Boolean);

            const columnTotals = calculateTotals(rows, sumColumns);

            return [
                ...tableSections,
                sumColumns.length > 0 ? (
                    <View>
                        <Text style={getTitleStyle(currentFilter.length)}>Total {tableName}</Text>
                        <TableRow
                            key="total"
                            columns={columns}
                            row={columnTotals}
                            rowStyles={styles.rowTotal}
                            cellStyles={styles.cell}
                            isTotal={true}
                        />
                    </View>
                ) : null
            ].filter((el): el is React.ReactElement => el !== null);
        }
    };

    const getTableHeader = (suppressColumnHeaders: boolean) => {
        if (suppressColumnHeaders) return null;

        return (
            <TableHeader
                columns={columns}
                rowStyles={styles.rowEven}
                cellStyles={styles.cell}
                headerRowStyles={styles.headerRow}
                headerCellStyles={styles.headerCell}
            />
        );
    };

   return (
        <View style={styles.table}>
            <Text style={styles.title}>{title}</Text>
            {getTableHeader(suppressColumnNames)}
            {renderTableRecursive("Holdings", rows, filter, true)}
        </View>
    );
}

export default ClientReportQuarterlyTable;
