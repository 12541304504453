class PortfolioDetails {
    portfolioScope: string;
    portfolioCode: string;
    portfolioCodes: string;
    recipe: string;
    reportingCurrency: string;
    modelId: string;
    keysToGroupBy: string;
    benchmarkCode: string;
    returnScope: string;
    returnScopeOutput: string;
    returnCode: string;
    benchmarkScope: string;
    benchmarkReturnScope: string;
    benchmarkRecipe: string;
    quotesScope: string;
    inceptionDate: string;
    serviceLevel: string;
    ombaBankAccountNumber: string;
    baseCurrency: string;
    accountingMethod: string;
    ombaCustodian: string;
    clientName: string;
    address: string;

    constructor(
        portfolioScope: string,
        portfolioCode: string,
        portfolioCodes: string,
        recipe: string,
        reportingCurrency: string,
        modelId: string,
        keysToGroupBy: string,
        benchmarkCode: string,
        returnScope: string,
        returnScopeOutput: string,
        returnCode: string,
        benchmarkScope: string,
        benchmarkReturnScope: string,
        benchmarkRecipe: string,
        quotesScope: string,
        inceptionDate: string,
        serviceLevel: string,
        ombaBankAccountNumber: string,
        baseCurrency: string,
        accountingMethod: string,
        ombaCustodian: string,
        clientName: string,
        address: string
    ) {
        this.portfolioScope = portfolioScope;
        this.portfolioCode = portfolioCode;
        this.portfolioCodes = portfolioCodes;
        this.recipe = recipe;
        this.reportingCurrency = reportingCurrency;
        this.modelId = modelId;
        this.keysToGroupBy = keysToGroupBy;
        this.benchmarkCode = benchmarkCode;
        this.returnScope = returnScope;
        this.returnScopeOutput = returnScopeOutput;
        this.returnCode = returnCode;
        this.benchmarkScope = benchmarkScope;
        this.benchmarkReturnScope = benchmarkReturnScope;
        this.benchmarkRecipe = benchmarkRecipe;
        this.quotesScope = quotesScope;
        this.inceptionDate = inceptionDate;
        this.serviceLevel = serviceLevel;
        this.ombaBankAccountNumber = ombaBankAccountNumber;
        this.baseCurrency = baseCurrency;
        this.accountingMethod = accountingMethod;
        this.ombaCustodian = ombaCustodian;
        this.clientName = clientName;
        this.address = address;
    }
}

export default PortfolioDetails;
