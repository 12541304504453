import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet, Svg, Rect } from '@react-pdf/renderer';
import RegisterFonts from "./registerFonts";

// Static Assets (Images)
import ombaCover from '../images/OMBA_Cover.jpg';
import insideBackCover from '../images/OMBA_landscape_2_Burgundy.jpg';
import BackCoverLandscape from '../images/BackCoverLandscape.png';

// Custom React-Pdf Elements
import ClientReportQuarterlyTable from "./modules/clientReportQuarterly/clientReportQuarterlyTable";
import Footer from "./modules/clientReportQuarterly/footer";
import Header from "./modules/clientReportQuarterly/header";

// Payload Interface
import ClientReportQuarterlyInfo from "../interfaces/props/reports/clientReportQuarterlyInfo";

// Default chart handling
import Chart from "../interfaces/props/reports/chart";

function ClientReportQuarterly(props: ClientReportQuarterlyInfo){
  const {
    clientCode,
    clientName,
    reportStartDate,
    reportEndDate,
    tables,
    charts
  } = props;

  RegisterFonts();


  const styles = StyleSheet.create({
    coverContainer: {
    },
    coverText: {
      position: "absolute",
      fontFamily: "Merriweather",
      fontWeight: "normal",
      color: "white",
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 45,
      paddingRight: 45,
      fontSize: 20,
    },
    coverImage: {
      position: "absolute",
    },
    heading: {
      color: "#801C1F",
      fontFamily: "Merriweather",
      fontWeight: "normal",
      fontSize: 28,
      paddingBottom: 2
    },
    subHeading: {
      color: "#1E3922",
      fontFamily: "Merriweather",
      fontWeight: "semibold",
      fontSize: 24,
      paddingBottom: 10
    },
    title: {
      fontSize: 14,
      fontWeight: "semibold",
    },
    standardText: {
      color: "#3C3C3B",
      fontFamily: "Merriweather",
      fontWeight: "light",
      fontSize: 11,
      lineHeight: 1.2,
      paddingBottom: 6
    },
    italicText: {
      fontStyle: "italic",
    },
    image: {
      objectFit: "contain",
      maxWidth: "100%",
      maxHeight: "100%"
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%"
    },
    backgroundImage: {
      position: "absolute",
      opacity: 0.8
    },
    chart: {
      flexGrow: 0,
      flexShrink: 1,
      maxWidth: "100%",
      height: "auto",
      paddingBottom: 10
    },
    logo: {
      position: "absolute",
      // flexBasis: "10%",
      // alignSelf: 'flex-end',
      top: 0,
      left: 700,
      zIndex: 2
    },
    half: {
      flexBasis: "50%",
    },
    row: {
      flexDirection: "row",
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    },
    column: {
      flexDirection: "column",
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingTop: 5,
      paddingBottom: 5
    },
    noShrink: {
      flexShrink: 0
    },
    table: {
      paddingLeft: 5,
      paddingRight: 5
    },
    tableGraphContent: {
        justifyContent: "space-evenly",
        alignItems: "baseline"
    },
    contentPage: {
        paddingLeft: 36,
        paddingRight: 36,
        backgroundColor: '#FFFFFF',
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingBottom: 94,
        paddingTop: 50
      },
      oneContent: {
        width: "100%",
        maxHeight: "85%",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 6,
        paddingTop: 16
      },
      twoContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        height: "85%",
        width: "100%",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 6,
        paddingTop: 16
      },
      content: {
        display: "flex",
        flexDirection: "column",
      },
      tableContainer: {
        display: "flex",
        flexWrap: "nowrap"
      },
      divider: {
        height: 1,
        backgroundColor: "black",
        marginBottom: 15
      },
      border: {
        position: "absolute",
        right: 0,
        top: 10
      }
  });

  const sumColumnsConfig = {
      assetAllocation: ['summedPercentage'],
      valuation: ['marketValueReportingCurrency', 'unrealisedGainReportingCurrency', 'portfolioPercentage'],
  };

  const getChartImage = (chart?: Chart) => chart?.image || '';
  const getChartCommentary = (chart?: Chart) => chart?.commentary || '';

  return (
      <Document>
        <Page size="A4" orientation="landscape">
          <Image style={[{ position: "absolute", top: 0, width: "99.9%", height: "99.9%" }]} src={ombaCover} />
          <Text style={[styles.coverText, { top: 225, fontSize: 16 }]}>Prepared for:</Text>
          <Text style={[styles.coverText, { top: 275, fontSize: 36 }]}>{clientName}</Text>
          <Text style={[styles.coverText, { top: 350, fontSize: 24, fontStyle: "italic" }]}>Investment Report</Text>
          <Text style={[styles.coverText, { top: 400 }]}>{`From ${reportStartDate} to ${reportEndDate}`}</Text>
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
          <Header {...{'title':'SUMMARY', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={styles.twoContent}>
            <View  style={[styles.content, { width: "40%", justifyContent: "space-around" }]}>
                <ClientReportQuarterlyTable {...{ tableData: tables.portfolioDetails }}/>
                <ClientReportQuarterlyTable {...{ tableData: tables.summary }} />
                <ClientReportQuarterlyTable {...{ tableData: tables.exchangeRates }} />
            </View>
            <View  style={[styles.content, { width: "50%", justifyContent: "space-around"}]}>
                <Image src={getChartImage(charts.performanceLineChart)} />
                <ClientReportQuarterlyTable {...{ tableData: tables.performance }} />
            </View>
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
            <Header {...{'title':'ASSET ALLOCATION', 'clientName':clientName, 'date':reportEndDate}}/>
            <View style={styles.twoContent}>
                <View style={[styles.content, { width: "30%"}]}>
                    <ClientReportQuarterlyTable {...{ tableData: tables.assetAllocation, sumColumns: ['summedPercentage'] }} />
                </View>
                <View style={[styles.content]}>
                    <Image style={styles.image} src={getChartImage(charts.clientPositionsAssetClassSunburst)} />
                </View>
            </View>
            <Footer />
        </Page>

        <Page style={[styles.contentPage, styles.noShrink]} size="A4" orientation="landscape">
          <Header {...{'title':'VALUATION: OVERVIEW', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={[styles.twoContent, { flexDirection: "row-reverse" }]} wrap>
            <View style={[ styles.content, { width: "50%" }]}>
                <ClientReportQuarterlyTable {...{
                    tableData: tables.clientPositionsBrief }} />
            </View>
            <View style={[styles.content, { width: "50%" }]}>
                <Image style={styles.image} src={getChartImage(charts.clientPositionsEtfSunburst)} />
            </View>
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
          <Header {...{'title':'VALUATION: ASSET CLASS BREAKDOWN', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={styles.oneContent} wrap>
              <ClientReportQuarterlyTable {...{
                  tableData: tables.allClientPositions,
                  sumColumns: ['marketValueReportingCurrency', 'unrealisedGainReportingCurrency', 'portfolioPercentage'],
                  filter: ['assetClass', 'assetSubClass'] }} />
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
            <Header {...{'title':'ETF ANALYSIS: EQUITY', 'clientName':clientName, 'date':reportEndDate}}/>
            <View style={styles.oneContent}>
                <Text style={[styles.standardText, styles.title]} fixed>TOP 10</Text>
                <Text style={[styles.standardText]}>The tables below show the top 10 holdings in each Equity ETF that you own, as well as some
                basic information about each of the holdings.</Text>
                <ClientReportQuarterlyTable {...{ tableData: tables.portfolioAnalysisTables.top10UnderlyingEquities }} />
            </View>
            <Footer/>
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
          <Header {...{'title':'ETF ANALYSIS: EQUITY', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={styles.oneContent}>
            <Text style={[styles.standardText, styles.title]}>Exposure by ETF and Industry</Text>
            <Text style={[styles.standardText]}>{getChartCommentary(charts.etfEquityIndustryExposureBarChart)}</Text>
            <View style={styles.imageContainer}>
                <Image style={styles.image} src={getChartImage(charts.etfEquityIndustryExposureBarChart)} />
            </View>
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
          <Header {...{'title':'ETF ANALYSIS: EQUITY', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={styles.oneContent}>
            <Text style={[styles.standardText, styles.title]}>Exposure by Industry and Sector</Text>
            <Text style={[styles.standardText]}>{getChartCommentary(charts.etfEquitySectorExposureBarChart)}</Text>
            <View style={styles.imageContainer}>
                <Image style={styles.image} src={getChartImage(charts.etfEquitySectorExposureBarChart)} />
            </View>
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
          <Header {...{'title':'ETF ANALYSIS: EQUITY', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={styles.oneContent}>
            <Text style={[styles.standardText, styles.title]}>Exposure by ETF and Country of Headquarters</Text>
            <Text style={[styles.standardText]}>{getChartCommentary(charts.etfEquityHeadquartersExposureBarChart)}</Text>
            <View style={styles.imageContainer}>
                <Image style={styles.image} src={getChartImage(charts.etfEquityHeadquartersExposureByEtfBarChart)} />
            </View>
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
          <Header {...{'title':'ETF ANALYSIS: EQUITY', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={styles.oneContent}>
          <Text style={[styles.standardText, styles.title]}>Overlap of holdings for each ETF</Text>
            <Text style={[styles.standardText]}>{getChartCommentary(charts.etfOverlapChart)}</Text>
            <View style={styles.imageContainer}>
                <Image style={styles.image} src={getChartImage(charts.etfOverlapChart)} />
            </View>
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
          <Header {...{'title':'FIXED INCOME ETFS', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={styles.oneContent}>
            <ClientReportQuarterlyTable {...{ tableData: tables.portfolioAnalysisTables.clientPositionsFixedIncome }} />
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
          <Header {...{'title':'FIXED INCOME ETFS', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={styles.oneContent}>
            <Text style={[styles.standardText, styles.title]}>Exposure by Credit Rating</Text>
            <Text style={[styles.standardText]}>{getChartCommentary(charts.etfFixedIncomeCreditRatingExposureBarChart)}</Text>
            <View style={styles.imageContainer}>
                <Image style={styles.image} src={getChartImage(charts.etfFixedIncomeCreditRatingExposureBarChart)} />
            </View>
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
          <Header {...{'title':'ACCOUNT ACTIVITY: CASH', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={styles.oneContent}>
            <ClientReportQuarterlyTable {...{ tableData: tables.cashTransactions }} />
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
          <Header {...{'title':'ACCOUNT ACTIVITY: TRADES', 'clientName':clientName, 'date':reportEndDate}}/>
          <View style={styles.oneContent}>
            <ClientReportQuarterlyTable {...{ tableData: tables.tradingTransactions }} />
          </View>
          <Footer />
        </Page>

        <Page style={styles.contentPage} size="A4" orientation="landscape">
            <Header {...{'title':'DISCLAIMER', 'clientName':clientName, 'date':reportEndDate}}/>
            <View style={styles.oneContent}>
              <Text style={styles.standardText}>Omba Advisory & Investments Limited is incorporated in England and Wales, Company Number 10594806. Omba Advisory & Investments Limited is authorised and regulated by the Financial Conduct Authority 775647.</Text>
              <Text style={styles.standardText}>In South Africa, Omba Advisory & Investments Limited is an authorised financial services provider (FSP No. 49101).</Text>
              <Text style={styles.standardText}>www.ombainvestments.com | www.ombafunds.com</Text>
              <Text style={styles.standardText}>Registered Address: Cargo Works (Unit 4.04), 1-2 Hatfields, London, SE1 9PG, United Kingdom</Text>
              <Text style={styles.standardText}>Appointed Auditors are MHA MacIntyre Hudson</Text>

              <Text style={[styles.standardText, { fontWeight: 'bold' }]}>Disclaimer</Text>
              <Text style={styles.standardText}>This material is for your information only and is not intended to be used by anyone other than you. This is not an offer or solicitation with respect to the purchase or sale of any security. The presentation is only to facilitate your discussions with Omba Advisory & Investments Limited. The given material is subject to change and although based on information which we consider reliable, it’s not guaranteed as to accuracy or completeness. The information contained in this document does not constitute an offer or solicitation of investment, financial or banking services by Omba Advisory & Investments Limited. This document may not be reproduced in whole or in part or copies circulated without the prior written consent of Omba Advisory & Investments Limited.</Text>
              <Text style={styles.standardText}>Past performance is not a guide to future returns and the value of your investments may fall as well as rise.</Text>
              <Text style={styles.standardText}>Omba does not provide any guarantee either with respect to the capital or income of the portfolios.</Text>
              <Text style={styles.standardText}>Please refer to your IMA and General Terms and conditions for further risk warnings.</Text>

              <Text style={[styles.standardText, { fontWeight: 'bold' }]}>Confidentiality</Text>
              <Text style={styles.standardText}>This document is confidential. It is not for circulation or publication and may not be disclosed to any third party without the written consent of Omba Advisory & Investments Limited. The financial arrangements outlined herein are for the benefit and information of the person to whom this document has been sent and submitted in good faith (“the addressee”), and who is deemed to have accepted responsibility for ensuring that the confidentiality thereof will be maintained at all times. It is not to be circulated nor shall its contents be disclosed to anyone other than the addressee, its employers and its professional advisors. Moreover, it shall not be reproduced or used, directly or indirectly, in whole or in part, for any purposes other than for internal consideration of the information described herein.</Text>
            </View>
            <Footer />
        </Page>

        <Page size="A4" orientation="landscape">
          <Image style={[styles.image, { width: "99.9%", height: "99.9%" }]} src={insideBackCover} />
        </Page>

        <Page size="A4" orientation="landscape">
          <Image style={[styles.image, { width: "99.9%", height: "99.9%" }]} src={BackCoverLandscape} />
        </Page>
      </Document>
  )
}

export default ClientReportQuarterly;
