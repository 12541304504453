class PortfolioSummary {
    reportingInterval: string;
    startDate: string;
    endDate: string;
    beginningMarketValue: number;
    deposits: number;
    withdrawals: number;
    changeInMarketValue: number;
    endingMarketValue: number;

    constructor(
        reportingInterval: string,
        startDate: string,
        endDate: string,
        beginningMarketValue: number,
        deposits: number,
        withdrawals: number,
        changeInMarketValue: number,
        endingMarketValue: number
    ) {
        this.reportingInterval = reportingInterval;
        this.startDate = startDate;
        this.endDate = endDate;
        this.beginningMarketValue = +beginningMarketValue;
        this.deposits = +deposits;
        this.withdrawals = +withdrawals;
        this.changeInMarketValue = +changeInMarketValue;
        this.endingMarketValue = +endingMarketValue;
    }
}

export default PortfolioSummary;
