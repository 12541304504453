import TransactionStore from "../models/transactions/stores/transactionStore";
import Transaction from "../models/transactions/transaction";

class TransactionViewModel {

    store: TransactionStore;

    constructor(transactionStore: TransactionStore) {
        this.store = transactionStore
    }

    getTransactions(clientCode: string, startDate: string, reportDate: string, portfolioScope: string,  useCached: boolean): Promise<Transaction[]>{
        const transactions = this.store.getTransactions(clientCode, startDate, reportDate, portfolioScope, useCached)
        return transactions;
    }
}

export default TransactionViewModel;