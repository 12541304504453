import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import {ombaApiScopes} from "../../../authConfig";
import PortfolioOverviewStore from "./portfolioOverviewStore";
import PortfolioDetails from "../portfolioDetails";
import PortfolioSummary from "../portfolioSummary";
import LusidPortfolioDetails from "../../../interfaces/lusid/lusidPortfolioDetails";
import LusidPortfolioSummary from "../../../interfaces/lusid/lusidPortfolioSummary";

class LusidPortfolioOverviewStore implements PortfolioOverviewStore{

    baseApiUrl: string;

    account?: AccountInfo;

    instance?: IPublicClientApplication;

    private accessToken?: string;

    constructor(baseApiUrl: string, account?: AccountInfo, instance?: IPublicClientApplication, accessToken?: string, useLUSID?: boolean){
        this.baseApiUrl = baseApiUrl;
        this.account = account;
        this.instance = instance;
        this.accessToken = accessToken;
    }

    setAccessToken(accessToken: string): void{
        this.accessToken = accessToken;
    }

    getAccessToken(): Promise<string>{

        if (this.accessToken != undefined){
            return new Promise((resolve, reject) => resolve(this.accessToken!))
        };

        return this.instance!.acquireTokenSilent({
            ...ombaApiScopes,
            account: this.account
        }).then((response) => {
            return response.accessToken;
        });
    }

    getDetails(accountNumber: string): Promise<PortfolioDetails>{
        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/client_details/?clientCode=${accountNumber}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
            .then(response => {
                if (!response.ok) {
                    return response.text().then(text => {
                        throw new Error(`HTTP error! status: ${response.status}, body: ${text}`);
                    });
                }
                return response.json();
            })
            .then(json => {
                return json[0] as LusidPortfolioDetails;
            })
            .then(res => {
                const portfolioDetails = new PortfolioDetails(
                    res.PortfolioScope,
                    res.PortfolioCode,
                    res.PortfolioCodes,
                    res.Recipe,
                    res.ReportingCurrency,
                    res.ModelId,
                    res.KeysToGroupBy,
                    res.BenchmarkCode,
                    res.ReturnScope,
                    res.ReturnScopeOutput,
                    res.ReturnCode,
                    res.BenchmarkScope,
                    res.BenchmarkReturnScope,
                    res.BenchmarkRecipe,
                    res.QuotesScope,
                    res.InceptionDate,
                    res.ServiceLevel,
                    res.Omba_BankAccountNumber,
                    res.BaseCurrency,
                    res.AccountingMethod,
                    res.Omba_Custodian,
                    res.ClientName,
                    res.Address
                );
                return portfolioDetails;
            });
        });
    }

    getSummary(
        accountNumber: string, 
        reportStartDate: string, 
        reportEndDate: string, 
        reportCurrency: string,
        portfolioScope: string,
        portfolioCodes: string,
        reportingCurrency: string,
        quotesScope: string,
        recipe: string,
        modelId: string,
        keysToGroupBy: string,
        inceptionDate: string,
        useCached: boolean
    ): Promise<PortfolioSummary[]> {
        return this.getAccessToken().then((accessToken) => {
            const params = new URLSearchParams({
                clientCode: accountNumber,
                reportStartDate: reportStartDate,
                reportEndDate: reportEndDate,
                useCached: useCached.toString(),
                reportCurrency: reportCurrency,
                portfolioScope: portfolioScope,
                portfolioCodes: portfolioCodes,
                reportingCurrency: reportingCurrency,
                quotesScope: quotesScope,
                recipe: recipe,
                modelId: modelId,
                keysToGroupBy: keysToGroupBy,
                inceptionDate: inceptionDate
            });

            return fetch(`${this.baseApiUrl}/account_summary/?${params}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            .then(response => {
                if (!response.ok) {
                    return response.text().then(text => {
                        throw new Error(`HTTP error! status: ${response.status}, body: ${text}`);
                    });
                }
                return response.json();
            })
            .then(json => {
                return json as LusidPortfolioSummary[];
            })
            .then(res => {
                return res.map(portfolioSummary => {
                    return new PortfolioSummary(
                        portfolioSummary['ReportingInterval'],
                        portfolioSummary['StartDate'],
                        portfolioSummary['EndDate'],
                        Number(portfolioSummary['BeginningMarketValue']),
                        Number(portfolioSummary['Deposits']),
                        Number(portfolioSummary['Withdrawals']),
                        Number(portfolioSummary['ChangeInMarketValue']),
                        Number(portfolioSummary['EndingMarketValue'])
                    )
                });
            })
            .catch(error => {
                throw new Error("Portfolio Summary: " + error);
            });
        });
    }
}

export default LusidPortfolioOverviewStore